import React, { useState, createContext, useContext, useEffect } from "react";
import { gql, useLazyQuery } from "@apollo/client";
import { redirectTo } from "../../history.jsx";

const AuthContext = createContext();
const useAuth = () => useContext(AuthContext);

const GET_MODULES = gql`
	query getModules(
		$userCode: Float!
		$systemCode: String!
		$menuParentCode: String!
	) {
		getModules(
			userCode: $userCode
			systemCode: $systemCode
			menuParentCode: $menuParentCode
		) {
			menuCode
			menuNameTH
			menuParentCode
			icon
			systemCode
			firstRoleMenu {
				menuCode
				menuNameTH
				menuParentCode
				menuPath
				canView
			}
			level1: childrenRoleMenus {
				menuCode
				menuNameTH
				menuParentCode
				menuPath
				canView
				level2: childrenRoleMenus {
					menuCode
					menuNameTH
					menuParentCode
					menuPath
					canView
				}
			}
		}
	}
`;

const GET_ROLE_MENU = gql`
	query userRoleMenuPermission($userCode: String!, $systemCode: String!) {
		userRoleMenuPermission(userCode: $userCode, systemCode: $systemCode) {
			userRoleMenuPermission
		}
	}
`;

const AuthProvider = ({ children }) => {
	const [data] = useState();
	const USERPROF = localStorage.getItem("USERPROF");

	const userAuth = USERPROF ? true : false;

	const [loadRoleMenu, { data: dataRoleMenu }] = useLazyQuery(GET_ROLE_MENU);
	const [loadModules, { data: dataModules }] = useLazyQuery(GET_MODULES);

	useEffect(() => {
		if (USERPROF) {
			let getUser = JSON.parse(USERPROF);
			loadRoleMenu({
				variables: {
					userCode: String(getUser.userCode),
					systemCode: process.env.REACT_APP_SYSTEMCODE
				}
			});

			loadModules({
				variables: {
					userCode: getUser.userCode,
					systemCode: process.env.REACT_APP_SYSTEMCODE,
					menuParentCode: "0000"
				}
			});
		}
	}, [USERPROF]);

	useEffect(() => {
		if (dataModules) {
			localStorage.setItem("MODULES", JSON.stringify(dataModules.getModules));
			//แก้ไขเพื่อให้ redirect ครั้งแรกไปที่เมนูเริ่มต้น แล้วถ้า redirect ครั้งต่อไปให้อยู่เมนูเดิม
			const defaultPath = "/exchange";
			const currentPath = window.location.pathname + window.location.search;
			const path =
				currentPath !== defaultPath && currentPath === "/"
					? defaultPath
					: currentPath;
			redirectTo(path);
		}
	}, [dataModules]);

	useEffect(() => {
		if (dataRoleMenu) {
			setDataUserRoleMenuPermission(dataRoleMenu).then(data => {
				localStorage.setItem("MENUPATH_ENABLE", JSON.stringify(data));
			});
		}
	}, [dataRoleMenu]);
	const setDataUserRoleMenuPermission = async infor => {
		let userRoleMenuPermission = JSON.parse(
			infor.userRoleMenuPermission.userRoleMenuPermission
		);
		let loopFn = main => {
			let tempMenuAllRole = [];
			let tempMenuAll = [];
			for (let i = 0; i < main.length; i++) {
				for (let j = 0; j < main[i].menu.length; j++) {
					tempMenuAllRole.push({
						menuCode: main[i].menu[j].menuCode,
						menuPath: main[i].menu[j].menuPath ? main[i].menu[j].menuPath : "",
						canAdd: main[i].menu[j].canAdd,
						canEdit: main[i].menu[j].canEdit,
						canView: main[i].menu[j].canView,
						canDelete: main[i].menu[j].canDelete
					});
					tempMenuAll.push(main[i].menu[j].menuCode);
				}
			}
			tempMenuAll = Array.from(new Set(tempMenuAll));
			let temp = tempMenuAll.map(data => {
				let menuDup = tempMenuAllRole.filter(sdata => {
					return String(data) === String(sdata.menuCode);
				});
				let canAdd = false;
				let canEdit = false;
				let canView = false;
				let canDelete = false;
				for (let i = 0; i < menuDup.length; i++) {
					if (menuDup[i].canAdd) {
						canAdd = true;
					}
					if (menuDup[i].canEdit) {
						canEdit = true;
					}
					if (menuDup[i].canView) {
						canView = true;
					}
					if (menuDup[i].canDelete) {
						canDelete = true;
					}
				}
				let action = [];
				if (canAdd) {
					action.push("create");
				}
				if (canEdit) {
					action.push("edit");
				}
				if (canView) {
					action.push("view");
				}
				if (canDelete) {
					action.push("delete");
				}
				return {
					menuCode: menuDup[0].menuCode,
					menuPath: menuDup[0].menuPath ? menuDup[0].menuPath : "",
					action: action
				};
			});
			return temp;
		};
		let userRoleMenu = await loopFn(userRoleMenuPermission);
		return userRoleMenu;
	};
	const login = input => {
		return new Promise((resolve, reject) => {
			localStorage.setItem("USERPROF", JSON.stringify(input.decryptData));
			localStorage.setItem("USER_EMAIL", input.decryptData.email);
			localStorage.setItem(
				"USER_FNAME",
				input.decryptData.firstname ? input.decryptData.firstname : ""
			);
			localStorage.setItem(
				"USER_LNAME",
				input.decryptData.lastname ? input.decryptData.lastname : ""
			);
			localStorage.setItem("USER_CODE", input.decryptData.userCode);
			localStorage.setItem("USER_SCHOOL_CODE", input.decryptData.schoolCode);
			localStorage.setItem(
				"USER_ORGANIZATION_CODE",
				input.decryptData.organizationCode
			);
			localStorage.setItem("USER_TYPE", input.decryptData.userType);
			localStorage.setItem(
				"USER_ORGANIZATION_NAME",
				input.decryptData.organizationNameTH
			);
			localStorage.setItem("USER_SCHOOL_NAME", input.decryptData.schoolName);
			resolve(input);
		});
	};

	const logout = () => {
		return new Promise((resolve, reject) => {
			try {
				localStorage.removeItem("USERPROF");
				resolve({ status: true });
			} catch (e) {
				reject(e);
			}
		});
	};

	return (
		<AuthContext.Provider value={{ data, login, logout, checkAuth: userAuth }}>
			{children}
		</AuthContext.Provider>
	);
};

export { AuthProvider, useAuth };
